var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "login-container" }, [
    _c(
      "div",
      { staticClass: "t-box" },
      [
        _c("h3", { staticStyle: { "text-align": "center", padding: "15px" } }, [
          _vm._v("用户登录"),
        ]),
        _c(
          "el-form",
          {
            ref: "ruleForm",
            attrs: {
              model: _vm.ruleForm,
              "status-icon": "",
              rules: _vm.rules,
              "label-width": "100px",
            },
          },
          [
            _c(
              "el-form-item",
              { attrs: { label: "用户名", prop: "username" } },
              [
                _c("el-input", {
                  attrs: { autocomplete: "off", placeholder: "test004" },
                  model: {
                    value: _vm.ruleForm.username,
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.ruleForm,
                        "username",
                        typeof $$v === "string" ? $$v.trim() : $$v
                      )
                    },
                    expression: "ruleForm.username",
                  },
                }),
              ],
              1
            ),
            _c(
              "el-form-item",
              { attrs: { label: "密码", prop: "password" } },
              [
                _c("el-input", {
                  attrs: {
                    type: "password",
                    placeholder: "123456",
                    autocomplete: "off",
                  },
                  model: {
                    value: _vm.ruleForm.password,
                    callback: function ($$v) {
                      _vm.$set(_vm.ruleForm, "password", $$v)
                    },
                    expression: "ruleForm.password",
                  },
                }),
              ],
              1
            ),
            _c("el-form-item", { attrs: { label: "验证码", prop: "vcode" } }, [
              _c(
                "div",
                { staticClass: "pass-box" },
                [
                  _c("el-input", {
                    attrs: { autocomplete: "off", maxlength: "4" },
                    model: {
                      value: _vm.ruleForm.vcode,
                      callback: function ($$v) {
                        _vm.$set(_vm.ruleForm, "vcode", $$v)
                      },
                      expression: "ruleForm.vcode",
                    },
                  }),
                  _c("img", {
                    staticClass: "vode-box",
                    attrs: { src: _vm.imgSrc },
                    on: { click: _vm.refresh },
                  }),
                ],
                1
              ),
            ]),
            _c(
              "div",
              {
                staticStyle: { "text-align": "center", "padding-left": "15px" },
              },
              [
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function ($event) {
                        return _vm.submitForm("ruleForm")
                      },
                    },
                  },
                  [_vm._v("登录")]
                ),
                _c(
                  "el-button",
                  {
                    staticClass: "login-btn",
                    attrs: { type: "text" },
                    on: {
                      click: function ($event) {
                        return _vm.$router.push({ path: "/register" })
                      },
                    },
                  },
                  [_vm._v("免费注册")]
                ),
              ],
              1
            ),
            _c(
              "div",
              {
                staticStyle: {
                  "text-align": "center",
                  padding: "10px 0",
                  "font-size": "14px",
                  color: "#666",
                },
              },
              [_vm._v("(体验账号：test004 密码：123456)")]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }